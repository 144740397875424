<script>
import Layout from '../layouts/main';
import { required } from 'vuelidate/lib/validators';
import { VMoney } from 'v-money';
import { api } from '@/state/services';

export default {
  locales: {
    pt: {
      'Invoices': 'Notas Fiscais',
      'Date': 'Data da Compra',
      'Number': 'Número da Nota',
      'Value': 'Valor da Compra',
      'Send': 'Enviar',
      'Security question': 'Pergunta de segurança',

      'Number is required.': 'Número da nota é obrigatório.',
      'Value is required.': 'Valor da nota é obrigatório.',
      'Date is required.': 'Data é obrigatório.',
      'Security question is required.': 'Pergunta de sergurança é obrigatório.',
      'What project do you day your coupon and competes for prizes?': 'Qual o projeto que você dia seu cupom e concorre a prêmios?',
      'Invoice created successfully.': 'Nota fiscal criada com sucesso.',
      'There was an error creating the invoice.': 'Ocorreu um erro ao criar a nota fiscal.',
      'Fill in all required fields.': 'Preencha todos os campos obrigatórios.',
    },
    es: {
      'Invoices': 'Facturas',
      'Date': 'Fecha da Compra',
      'Number': 'Número de Nota',
      'Value': 'Valor da Compra',
      'Send': 'Enviar',
      'Security question': 'Pregunta de seguridad',

      'Number is required.': 'Se requiere el número de nota.',
      'Value is required.': 'El valor de la nota es obligatorio.',
      'Date is required.': 'Se requiere fecha.',
      'Security question is required.': 'La pregunta de seguridad es obligatoria.',
      'What project do you day your coupon and competes for prizes?': '¿En qué proyecto haces tu cupón y compite por premios?',
      'Invoice created successfully.': 'Factura creada correctamente.',
      'There was an error creating the invoice.': 'Hubo un error al crear la factura.',
      'Fill in all required fields.': 'Complete todos los campos obligatorios.',
    }
  },
  components: {
    Layout
  },
  data () {
    return {
      invoice: {
        cnpj: '',
        number: '',
        value: '',
        date: '',
      },

      alert: {
        invoice: { type: '', message: '' }
      },

      question: '',

      loading: false,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },
    }
  },
  directives: { money: VMoney },
  validations: {
    invoice: {
      cnpj: { required },
      number: { required },
      value: { required },
      date: { required },
    },
  },
  methods: {
    invoiceSubmit() {
      this.loading = true
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        api
          .post('invoice', {
            cnpj: this.invoice.cnpj,
            number: this.invoice.number,
            value: this.invoice.value,
            date: this.invoice.date,
          })
          .then(response => {
            if (response.data.status==='success') {
              this.invoice.value = '0,00';
              this.$v.$reset();

              this.alert.invoice.type = 'alert-success';
              this.alert.invoice.message = 'Nota discal salva com sucesso';

              this.$router.push('/invoices');
            } else {
              this.alert.invoice.type = 'alert-danger';
              this.alert.invoice.message = response.data.message;
            }

            this.loading = false;
          })
          .catch(error => {
            if (error) {
              this.loading = false
            }
          });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Invoices') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="card col-lg-5">
        <div class="card-body">
          <b-form class="p-3" @submit.prevent="invoiceSubmit">
            <div v-if="alert.invoice.message" :class="'alert ' + alert.invoice.type">{{ t(alert.invoice.message) }}</div>

            <b-form-group id="cnpj" :label="t('CNPJ do Estabelecimento')" label-for="cnpj">
              <b-form-input v-model="invoice.cnpj" type="text" :class="{ 'is-invalid': $v.invoice.cnpj.$error }" v-mask="'##.###.###/####-##'"></b-form-input>
              <div v-if="$v.invoice.$error" class="invalid-feedback">
                <span v-if="!$v.invoice.cnpj.required">{{ t('CNPJ is required.') }}</span>
              </div>
            </b-form-group>

            <b-form-group id="number" :label="t('Number')" label-for="number">
              <b-form-input v-model="invoice.number" type="text" :class="{ 'is-invalid': $v.invoice.number.$error }"></b-form-input>
              <div v-if="$v.invoice.$error" class="invalid-feedback">
                <span v-if="!$v.invoice.number.required">{{ t('Number is required.') }}</span>
              </div>
            </b-form-group>

            <b-form-group id="value" :label="t('Value')" label-for="value">
              <b-form-input v-model="invoice.value" v-money="money" type="text" inputmode="decimal" :class="{ 'is-invalid': $v.invoice.value.$error }"></b-form-input>
              <div v-if="$v.invoice.$error" class="invalid-feedback">
                <span v-if="!$v.invoice.value.required">{{ t('Value is required.') }}</span>
              </div>
            </b-form-group>

            <b-form-group id="date" :label="t('Date')" label-for="date">
              <b-form-input v-model="invoice.date" type="date" :class="{ 'is-invalid': $v.invoice.date.$error }"></b-form-input>
              <div v-if="$v.invoice.$error" class="invalid-feedback">
                <span v-if="!$v.invoice.date.required">{{ t('Date is required.') }}</span>
              </div>
            </b-form-group>

            <div class="mt-4">
              <b-button :disabled="this.loading == true || !this.invoice.cnpj || !this.invoice.number || !this.invoice.value || !this.invoice.date" type="submit" variant="danger">
                {{ t('Send') }}
                <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>